
.header-section{
    background-color: #59258A;
}
.navbar-1{
border-radius: 16px;
background-color: white !important;
}
.navbar-heading{
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    line-height: 36.57px;  
    color: #102E3C !important;
}
.navbar-heading-span{
    font-family: Montserrat;
font-size: 34px;
font-weight: 500;
line-height: 41.45px;
color: #102E3C !important;
}
@media (max-width:767px) {
    .navbar-heading{
        font-size: 25px;
    }
    .navbar-heading-span{
    font-size: 28px;
    }
}
.navbar-1 ul li a{
    color: #341552;
}
.navbar-button{
padding: 8.25px 9px 8.25px 9px;
gap: 0px;
border-radius: 8px;
border: 1px;
background-color: #8748C2;
color: white !important;
border: 1px solid #FAFAFA;
}
.toggler-btn svg{
    color:  white !important;
}
.toggler-btn{
    background-color: #59258A !important;
    border: none !important;
}
@media (max-width:991px) {
    .navbar-1 ul{
        text-align: center;
        width: 25%;
        margin: 0 auto;
    }
}
@media (max-width:500px) {
    .navbar-1 ul{
        text-align: center;
        width: 30%;
        margin: 0 auto;
    }
}
@media (max-width:400px) {
    .navbar-1 ul{
        text-align: center;
        width: 37%;
        margin: 0 auto;
    }
}
.hero-section-title{
    font-family: General Sans;
font-size: 18px;
font-weight: 500;
line-height: 18px;
color: #FF576D;
}
.hero-section-heading{
    font-family: General Sans;
font-size: 58px;
font-weight: 600;
line-height: 80px;
color: #F8F9FA;
width: 79%;
}
@media (max-width:1535px) {
    .hero-section-heading{
        width: 98%;
    }
}
@media (max-width:1279px) {
    .hero-section-heading{
        width: 100%;
    }
}
@media (max-width:1279px) {
    .hero-section-heading{
    font-size: 50px;
    }
}
.hero-section-text{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
text-align: left;
color: #F8F9FA;
}
.hero-section-btn{
padding: 11px 21px 11px 21px;
border-radius: 8px;
background-color: #FF576D;
color: #FFFFFF;
}
@media (max-width:767px) {
    .hero-section-title{
    font-size: 16px;
    }
    .hero-section-heading{
    font-size: 35px;
    line-height: 48px;
    }
    .hero-section-text{
    font-size: 18px;
    }
}
@media (max-width:1279px) {
    .image1-custom-s2{
        width: 8%;
    }
    .image-custom-s2{
    width: 20%;
    }   
}
.bg-pg1-s2{
    background-color: #FBF7FF;
}
.s3-pg1-title{
    font-family: General Sans;
font-size: 18px;
font-weight: 500;
line-height: 18px;
color: #FF576D;
}
.s3-pg1-heading1{
    font-family: General Sans;
    font-size: 58px;
    font-weight: 600;
    line-height: 80px;
    color:#341552;
}
.s3-pg1-heading{
    font-family: General Sans;
font-size: 58px;
font-weight: 600;
line-height: 80px;
color:#341552;
width: 72%;
}
.s3-pg1-text{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
color: #341552;
}
@media (max-width:1535px) {
    .s3-pg1-heading{
    width: 84%;
    }
}
@media (max-width:1279px) {
    .s3-pg1-heading{
    font-size: 50px;
    width: 100%;
    }
}
@media (max-width:1279px) {
    .s3-pg1-heading1{
    font-size: 50px;
    }
}
@media (max-width:1023px) {
    .s3-pg1-heading{
    width: 100%;
    }
}
@media (max-width:767px) {
    .s3-pg1-heading1{
    font-size: 35px;
    line-height: 48px;
    }
}
@media (max-width:767px) {
    .s3-pg1-title{
    font-size: 16px;
    }
    .s3-pg1-heading{
    font-size: 35px;
    line-height: 48px;
    }
    .s3-pg1-text{
    font-size: 18px;
    }
}
.s4-pg1-card1{
border-radius: 20px;
background-color: #F8F4FE;
height: 390px;
}
@media (max-width:1279px) {
    .s4-pg1-card1{
        height: 450px;
    }
}
.s4-pg1-card2{
    background-color: #F9DCDB !important;
    }
.s4-pg1-heading{
    font-family: General Sans;
font-size: 27px;
font-weight: 600;
color: #341552;
}
.s4-pg1-text{
    font-family: General Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: #341552;
}
@media (max-width:1279px) {
    .s4-pg1-img{
        width: 123px;
        height: 123px;
    }    
    .s4-pg1-heading{
    font-size: 23px;
    }
    .s4-pg1-text{
        font-size: 19px;
    }
}
@media (max-width:767px) {

    .s4-pg1-heading{
    font-size: 19px;
    }
    .s4-pg1-text{
        font-size: 15px;
        line-height: 25px;
    }
    .s4-pg1-card1{
        border-radius: 20px;
        background-color: #F8F4FE;
        height: 320px;
        }
}
.s5-pg1-btn{
    font-family: General Sans;
font-size: 18px;
font-weight: 400;
line-height: 34px;
color:#E74C3C;
text-decoration: none;
cursor: pointer;
}
.s5-pg1-bg{
    background-color: #FCEDE4;
}
.s6-pg1-position {
    left: -22%;
}
.s7-pg1-number{
    font-family: General Sans;
    font-size: 37px;
    font-weight: 500;
    line-height: 60px;
    color: #341552;
}
.s7-pg1-heading1{
    font-family: General Sans;
font-size: 40px;
font-weight: 600;
line-height: 60px;
color: #341552;
}
@media (max-width:1279px) {
    .s7-pg1-number{
        font-size: 25px;
        line-height: 50px;
    }
    .s7-pg1-heading1{
    font-size: 28px;
    line-height: 50px;
    }
}
.active .s7-pg1-heading1{
    color: #ED6471 !important;
}
.active .s7-pg1-number{
    color: #ED6471 !important;
}
@media (max-width:1023px) {
    .active img{
        width: 50px;
    }   
}
@media (max-width:767px) {
    .active img{
        width: 30px;
    }   
    .s6-pg1-position {
        left: -9%;
    }
}
@media (max-width:660px) {
    .s6-pg1-position {
        left: -7%;
    }
}
@media (max-width: 500px) {
    .s6-pg1-position {
        left: -8%;
    }
}
@media (max-width: 450px) {
    .s6-pg1-position {
        left: -11%;
    }
}
.comet-position-pg1-s7{
    top: -7%;
    right: 2%;
}
@media (max-width:767px) {
    .comet-position-pg1-s7{
        height: 75px;
        top: -3%;
    }
}
.moon-pg1-positon{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 90px;
}
@media (max-width:767px) {
    .moon-pg1-positon{
        top: 0 !important;
        height: 75px;
    }
}
.s8-border-pg1{
border-radius: 68.5px;
border: 3px solid #DAB3FF;
padding: 14px 30px 14px 30px;
}
.s8-heading-pg1{
    font-family: General Sans;
font-size: 26px;
font-weight: 600;
}
.s8-border-pg1 img{
    height: 40px;
}
@media (max-width:1466px) {
    .s8-border-pg1 {
        padding: 14px 20px 14px 20px;
    }
}
@media (max-width:1383px) {
    .s8-heading-pg1{
    font-size: 23px;
    }
}
@media (max-width: 1299px) {
    .s8-border-pg1 {
        padding: 8px 16px 8px 16px;
    }
}
@media (max-width:1023px) {
    .s8-border-pg1 img{
        height: 45px;
    }    
}
@media (max-width: 767px) {
    .s8-heading-pg1 {
        font-size: 18px;
    }
}
@media (max-width: 618px) {
    .s8-heading-pg1 {
        font-size: 16px;
    }
    .s8-border-pg1{
        width: 230px;
        padding: 14px 14px;
    }
    .s8-border-pg1 img{
        height: 35px;
    }
}
@media (max-width: 507px) {
    .s8-heading-pg1 {
        font-size: 13px;
    }
    .s8-border-pg1{
        width: 180px;
        padding: 10px 10px;
    }
    .s8-border-pg1 img{
        height: 25px;
    }
}
.s9-bg-pg1{
    background-color: #59258A;
border-radius: 16px 16px 0px 0px;
}
.s9-heading-pg1{
    font-family: General Sans;
font-size: 58px;
font-weight: 600;
line-height: 80px;
color: #FFFFFF;
}
.s9-text-pg1{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
color: #FFFFFF;
}
@media (max-width:1535px) {
    .s9-heading-pg1{
    font-size: 50px;
    }
}
@media (max-width:767px) {
    .s9-heading-pg1{
    font-size: 35px;
    line-height: 48px;
    }
}
@media (max-width:767px) {
    .s9-text-pg1{
    font-size: 18px;
    }
}
.s9-border-pg1{
padding: 26.5px 52.21px 25.5px 26px;
border-radius: 16px;
border: 1px solid #E5D4F4
}
.s9-heading2-pg1{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 27px;
color: #E5D4F4;
}
@media (max-width:1535px) {
    .s9-border-pg1{
        padding: 26px 26px 26px 26px;
        border-radius: 16px;
        border: 1px solid #E5D4F4
        }
}
.s9-custom-gap-pg1{
    gap: 85px;
}
@media (max-width:1535px) {
    .s9-custom-gap-pg1 {
        gap: 35px;
    }
}
@media (max-width:767px) {
    .s9-custom-gap-pg1 img{
        height: 40px;
    }
    .s9-heading2-pg1{
        font-size: 17px;
        }
        .s9-custom-gap-pg1 {
            gap: 10px;
        }
}
.custom-position-s9-pg1{
    top: 10px;
    left: 0;
}
@media (max-width:767px) {
    .custom-position-s9-pg1{
        height: 60px;
    }
}
.custom-position1-s9-pg1{
    right: 10%;
    bottom: 0;
}
@media (max-width:1535px) {
    .custom-position1-s9-pg1{
       height: 100px;
    }
}
@media (max-width:1279px) {
    .custom-position1-s9-pg1{
       height: 80px;
    }
}

@media (max-width: 1023px) {
    .custom-position1-s9-pg1 {
        right: -32px;
        bottom: -35px;
    }
}
@media (max-width:767px) {
    .custom-position1-s9-pg1 {
        bottom: -39px;
        height: 40px;
        right: -11px;
    }
}
.s10-bg-pg1{
    background: #59258A;
border-radius: 20px;
border-top: 2px solid #FFFDFA
}
.s10-heading-pg1{
    font-family: General Sans;
font-size: 44px;
font-weight: 600;
line-height: 61px;
color: #FFFFFF;
}
.s10-text-pg1{
    font-family: General Sans;
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    color: #FFFFFF;   
    width: 75% !important; 
}
@media (max-width: 1535px) {
    .s10-text-pg1{ 
        width: 100% !important;
    }
    .s10-heading-pg1 {
        font-size: 39px;
    }
}
@media (max-width: 1279px) {
    .s10-heading-pg1 {
        font-size: 36px;
    }
}
@media (max-width:1023px) {
    .s10-text-pg1{ 
        width: 100% !important;
    }
}
@media (max-width: 767px) {
    .s10-heading-pg1 {
        font-size: 25px;
        line-height: 40px;
    }
    .s10-text-pg1{
        font-size: 19px;
        line-height: 25px;
    }
}
.positon-s10-pg1{
    position: relative;
}
.positon1-s10-pg1{
    position: absolute;
    right: 5%;
}
@media (max-width:1023px) {
    .positon1-s10-pg1{
        position: unset;
    }
    .positon-s10-pg1{
        position: unset;
        display: flex;
        justify-content: center;
    }
}
.s10-text3-pg1{
    font-family: General Sans;
font-size: 32px;
font-weight: 400;
line-height: 61px;
color: #C77FFF;
cursor: pointer;
}
.activeTechnology{
    color: #FFFFFF;
}
@media (max-width:767px) {
    .s10-text3-pg1{
        font-size: 24px;
    }
}
.s10-card-pg1{
padding: 11px 45.25px 11.02px 45.25px;
border-radius: 12px;
background-color: #FFFFFF;
border: 1px solid #EFDFDF
}
@media (max-width:1535px) {
    .s10-card-pg1 {
        padding: 11px 25.25px 11.02px 25.25px;
    }
}
@media (max-width:1279px) {
    .s10-card-pg1 {
        padding: 11px 8.25px 11.02px 8.25px;
    }
}
@media (max-width:1023px) {
    .s10-card-pg1 {
        padding: 11px 45.25px 11.02px 45.25px;
    }
}

@media (max-width: 767px) {
    .s10-card-pg1 {
        padding: 11px 15.25px 11.02px 15.25px;
    }
}
@media (max-width:1023px) {
    .verticalDivider{
        display: none;
    }
}
.s11-pg1-width{
    width: 82% !important;
}
@media (max-width:1023px) {
    .s11-pg1-width{
        width: 100% !important;
    }
}
.s11-position-pg1{
    right: 0;
    top: 64%;
}
@media (max-width:1279px) {
    .s11-position-pg1 img{
        height: 150px;
        }            
}
@media (max-width:767px) {
    .s11-position-pg1 img{
        height: 90px;
        }     
        .s11-position-pg1{
            right: 0;
            top: 90%;
        }       
}
.card-s12-pg1{
padding: 40px 30px 40px 40px;
border-radius: 16px;
background-color: #FFF1EA;
height: 485px;
}
@media (max-width:767px) {
    .card-s12-pg1{
        padding: 40px 30px 40px 40px;
        border-radius: 16px;
        background-color: #FFF1EA;
        height: 450px;
        }   
}
.card-s12-pg1-title{
    font-family: General Sans;
font-size: 16px;
font-weight: 500;
line-height: 19.2px;
letter-spacing: 4.679999828338623px;
color: #341552;

}
.card-s12-pg1-heading{
    font-family: General Sans;
font-size: 46px;
font-weight: 500;
line-height: 60px;
color: #341552;

}
.card-s12-pg1-text{
    font-family: General Sans;
font-size: 18px;
font-weight: 400;
line-height: 34px;
color: #341552;
}
@media (max-width:767px) {
    .card-s12-pg1-heading {
        font-size: 32px;
        line-height: 50px;
    }
}
.s13-pg1-bg{
border-radius: 24px;
background-color: #D44FCB;
padding: 30px 30px 0px 30px;
height: 581px;
}
.s13-pg1-title{
    font-family: General Sans;
font-size: 18px;
font-weight: 500;
line-height: 24px;
color: #FFFFFF;
}
.s13-pg1-heading{
    font-family: General Sans;
font-size: 44px;
font-weight: 500;
line-height: 52px;
color: #FFFFFF;
}

@media (max-width: 1279px) {
    .s13-pg1-heading {
        font-size: 36px;

    }
}
@media (max-width:767px) {
    .s13-pg1-title{
    font-size: 16px;
    } 
    .s13-pg1-heading {
        font-size: 25px;
        line-height: 40px;
    }
}
.s13-pg1-bg1{
border-radius: 24px;
background-color: #FCEDFB;
height: 581px;
}

.s13-pg1-inner-bg1{
border-radius: 24px;
background-color: #FFDDFD;
padding: 20px;
}
.custom-padding-pg1-s13{
    padding: 20px;
}
.inner-img-s13{
padding: 15px;
border-radius: 34px;
background-color: #FFFFFF;
}
.custom-padding1-pg1-s13{
padding: 20px 20px 0px 20px;
}
.ronded-box-s13-pg1{
    padding: 10px 15px 10px 15px;
border-radius: 50px;
border: 1px solid #EC39B7
}
.inner-s13-pg1-title{
    font-family: General Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #EC39B7;    
}
.inner-s13-pg1-text{
    font-family: General Sans;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    color: #341552;    
}
.inner-s13-pg1-title1{
    font-family: General Sans;
font-size: 28px;
font-weight: 400;
line-height: 36px;
color: #341552;   
}
.rounded-box-text-s13{
    font-family: General Sans;
font-size: 16px;
font-weight: 400;
color: #EC39B7;
}
.position-img1-s13{
    right: 15%;
}
@media (max-width:1279px) {
    .s13-pg1-bg{
        height: 625px;
    }
    .s13-pg1-bg1{
        height: 625px;
    }
    .inner-s13-pg1-text {
        font-size: 22px;
        line-height: 30px;
    }
    .inner-s13-pg1-title1{
        font-size: 24px;
    }
    .position-img1-s13{
        right: 3%;
    }
}
@media (max-width:1023px) {
    .s13-pg1-bg{
        height: auto;
    }
    .s13-pg1-bg1{
        height: auto;
    }
    .position-img1-s13 {
        position: unset !important;
    }
}
@media (max-width:767px) {
    .inner-s13-pg1-text {
        font-size: 20px;
    }
    .inner-s13-pg1-title1{
        font-size: 22px;
    }
    .rounded-box-text-s13 {
        font-size: 13px;
    }
    .inner-img-s13 {
        padding: 7px;
        height: 45px;
    }
}
.s13-pg1-bg3{
    background-color: #A191F5 !important;
}
.s13-pg1-title1{
color: #341552 !important;
}
.s13-pg1-heading1{
    color: #341552 !important;
}
.custom-padding5-s13{
    padding: 50px 50px 0px 50px !important;
    right: 10% !important;
}
@media (max-width:1535px) {
    .custom-padding5-s13{
        right: 2% !important;
    }
}
.s13-pg1-bg4{
    background-color: #E4E1FD !important;
}
.s13-pg1-inner-bg2{
    background-color: #DBD4FF !important;
}
.inner-s13-pg1-title1{
    color: #341552 !important;
}
.inner-s13-pg1-text1{
    color: #341552 !important;
}
.rounded-box1-s13-pg1{
    color: #341552 !important;
    border: 1px solid #341552 !important;
}
.rounded-box-text-s13{
    color: #341552 !important;
}

@media (max-width: 1535px) {
    .s13-pg1-bg4 {
        height: 625px !important;
    }
    .s13-pg1-bg3{
        height: 625px !important;
    }
}
@media (max-width: 1023px) {
    .s13-pg1-bg4 {
        height: auto !important;
    }
    .s13-pg1-bg3{
        height: auto !important;
    }
}
.s13-pg1-bg5{
    background-color: #11454D !important;
    height: 530px !important;
}

.s13-pg1-heading3{
color: #14C779 !important;
}
.s13-pg1-title3{
    color: #14C779 !important;
}
.custom-padding6-s13{
    padding: 50px 50px 0px 50px !important;
    right: 9% !important;
}
@media (max-width:1535px) {
    .custom-padding6-s13{
        right: 0% !important;
    }
}
.s13-pg1-bg8{
    background-color: #E8FDF3 !important;
    height: 530px !important;
}
.s13-pg1-inner-bg9{
    background-color: #D0FBE6 !important;
}

@media (max-width:1279px) {
    .s13-pg1-bg8{
        height: 600px !important;
    }
    .s13-pg1-bg5{
        height: 600px !important;
    }
}
@media (max-width:1023px) {
    .s13-pg1-bg8{
        height: auto !important;
    }
    .s13-pg1-bg5{
        height: auto !important;
    }

}
.rounded-box-text-s13{
    color: #11454D !important;
}
.ronded-box6-s13-pg1{
    border: 1px solid #11454D !important;
}
.bg-img-s14{
        background-image: url('/public/assets/d3c33dbfaf75f8ec2226e932070602da.png');
        background-size: cover; 
        background-repeat: no-repeat;
        background-position: center;
        height: 1010px;
}
.card-s14-pg1{
    background-color: #FFFFFF;
}
.mySwiper {
    width: 451px;
    height: 430px;
}
  
  .swiper-slide-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  
  .swiper-slide-2:nth-child(1n) {
    background-color:#FFFFFF;
  }
  
  .swiper-slide-2:nth-child(2n) {
    background-color:#FFFFFF;
  }
  
  .swiper-slide-2:nth-child(3n) {
    background-color:#FFFFFF;
  }
  
  .home-slider .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
    background-image: url('/public/assets/Button - Previous slide → real-prev.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
}
.home-slider .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: '' !important;
    background-image: url('/public/assets/real-next.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
}
.swiper-slide-2-text{
    font-family: General Sans;
font-size: 25px;
font-weight: 400;
line-height: 40px;
color: #341552 !important;
}
.swiper-slide-2-heading{
    font-family: General Sans;
font-size: 32px;
font-weight: 400;
line-height: 43px;
    color: #9948E8 !important;
}
.swiper-slide-2-card{
border-radius: 20px;
padding: 24px;

}
.swiper-slide-2-heading1{
    font-family: General Sans;
font-size: 22px;
font-weight: 500;
line-height: 26.4px;
color: #341552;
}
@media (max-width:1279px) {
    .swiper-slide-2-text{
    font-size: 22px;
    }
    .swiper-slide-2-heading{
    font-size: 28px;
    }
    .swiper-slide-2-heading1{
    font-size: 20px;
    }
    .mySwiper{
        width: 416px !important;
    }
}
@media (max-width:767px) {
    .swiper-slide-2-text{
    font-size: 20px;
    }
    .swiper-slide-2-heading{
    font-size: 24px;
    }
    .swiper-slide-2-heading1{
    font-size: 18px;
    }
    .mySwiper{
        width: 350px !important;
    }
}
.horiziontal-line-slide{
    border-top-width: 3px !important;
    border-color: #cba9ec !important;
}
.home-slider .swiper-button-prev {
    left: -16% !important;
    height: 60px !important;
    width: 60px !important;
}
.home-slider .swiper-button-next {
    right: -24% !important;
    height: 60px !important;
    width: 60px !important;
}
.swiper-slide-visible{
    opacity: 1 !important;
}
.swiper-slide-2{
    opacity: 0.5;
}
@media (max-width:1100px) {
    .home-slider .swiper-button-next{
        right: -16% !important;
        
    }
}
@media (max-width:1023px) {
    .home-slider .swiper-button-next{
            right: -26% !important;
            
        }
        .home-slider .swiper-button-prev{
            left: -26% !important;
        }
        .bg-img-s14 {
            height: 1010px;
        }
}
.s14-heading-pg1{
    color: #FFFFFF !important;
    width: 49% !important;
}
.s14-text-pg1{
    color: #FFFFFF !important;
    width: 96% !important;
}
@media (max-width:1279px) {
    .s14-text-pg1{
        width: 78% !important; 
    }
}
.s14-btn1-pg1{
padding: 11px 31px 11px 31px;
border-radius: 8px;
border: 1px solid #FFFFFF;
color:#FFFFFF;
}

@media (max-width:767px) {
    .bg-img-s14 {
        height: 1080px;
    }
    .home-slider .swiper-button-prev, .home-slider .swiper-button-next {
        top:115% !important;
    }
    .home-slider .swiper-button-prev {
        left:34% !important;
    }
    .home-slider  .swiper-button-next{
        right:34% !important;
    }
    .home-slider .swiper-button-prev {
        height: 40px !important;
        width: 40px !important;
    }
    .home-slider  .swiper-button-next {
        height: 40px !important;
        width: 40px !important;
    }
}
@media (max-width:450px) {
    .mySwiper {
        width: 294px !important;
        height: 500px !important;
    }    
    .home-slider .swiper-button-prev {
        left:31% !important;
    }
    .home-slider .swiper-button-next{
        right:31% !important;
    }
        .bg-img-s14 {
            height: 1140px;
        }
}
.s16-pg1-heading{
    width: 67% !important;
}
@media (max-width:1279px) {
    .s16-pg1-heading{
        width: 89% !important;
    }
}
@media (max-width:1023px) {
    .s16-pg1-heading{
        width: 100% !important;
    }
}
.s15-pg1-text{
    margin-top: 16px;
    width: 89% !important;
}
@media (max-width:1535px) {
    .s15-pg1-text {
        width: 100% !important;
    }
}
@media (max-width:1023px) {
    .s15-pg1-text{
        margin-top: 0px;
    }
}

.modal-width{
    max-width: 60rem;
}