.hero-section-heading-contactus{
    width: 66% !important;
    color: #341552 !important;
}
.hero-section-text-contactus{
    width: 72%;
    color: #341552 !important;
}
@media (max-width:1535px) {
    .hero-section-heading-contactus {
        width: 71% !important;
    }
    .hero-section-text-contactus{
        width: 82%;
    }
}
@media (max-width:1279px) {
        .hero-section-heading-contactus {
            width: 85% !important;
        }
        .hero-section-text-contactus{
            width: 100%;
        }
}
@media (max-width:1023px) {
    .hero-section-heading-contactus {
        width: 100% !important;
    }
}
.contact-us-section{
    background-color: #F2E4FF;
    border-radius: 16px;
}
.contact-us-section-bg{
    background-color: #59258A;
    border-radius: 16px;
}
.contactUsection-grid2{
    background-color: #FFFFFF;
    border-radius: 8px !important;
}

.footer1-border-contact{
    height: 500px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.heading-contact-footer{
    color: #FFFFFF !important;
}
.contact-footer-text{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
color: #FFFFFF;
}
.footer1-tab2{
    width: 52% !important;
}
@media (max-width:1279px) {
    .footer1-tab2 {
        width: 69% !important;
    }
    .footer-contact-position{
        right: 2%;
    }
}
@media (max-width: 1023px) {
    .footer1-tab2 {
        width: 63% !important;
    }
}
@media (max-width:767px) {
    .contact-footer-text {
        font-size: 18px !important;
    }
}
@media (max-width: 539px) {
    .footer1-tab2 {
        width: 70% !important;
    }
    .footer1-border-contact{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
@media (max-width: 440px) {
    .footer1-tab2 {
        width: 82% !important;
    }
    .footer1-box-heading-contact{
    font-size: 16px !important;
    }
}
.padding-contact-section-custom{
    padding-left: 20px;
    padding-right: 20px;
}
.section1-contact-heading{
    font-family: General Sans;
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
    color: #341552;
}
.section1-contact-text{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
color: #341552;
width: 31%;
}
@media (max-width:1535px) {
    .section1-contact-text{
    width: 36%;
    }
}
@media (max-width:1279px) {
    .section1-contact-text{
    width: 49%;
    }
}
@media (max-width:1023px) {
    .section1-contact-text{
    width: 65%;
    }
}
@media (max-width:767px) {
    .section1-contact-text{
    width: 76%;
    }
    .section1-contact-heading{
        font-size: 26px;
    }
    .section1-contact-text{
    font-size: 18px;
    }
}