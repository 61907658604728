.hero-section-heading-portfolio {
    color: #341552 !important;
    width: 79% !important;
}
@media (max-width:1535px) {
    .hero-section-heading-portfolio {
        width: 96% !important;
    }
}
@media (max-width:1279px) {
    .hero-section-heading-portfolio {
        width: 100% !important;
    }
}
.s1-pg3-portfolio-bg{
        background: linear-gradient(87.99deg, #E265BE 0%, #F317B2 100%);
        padding: 80px 0px 80px 68px;
        border-radius: 16px;
}
@media (max-width: 1023px) {
    .s1-pg3-portfolio-bg {
        padding: 40px 40px 0px 40px;
    }
}
@media (max-width: 767px) {
    .s1-pg3-portfolio-bg {
        padding: 40px 20px 0px 20px;
    }
}
.s1-title-pg3{
    font-family: General Sans;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: white;
}
@media (max-width: 767px) {
    .s1-title-pg3 {
        font-size: 16px;
    }
}
.s1-heading-pg3{
    font-family: General Sans;
    font-size: 44px;
    font-weight: 500;
    line-height: 52px;
    color: white !important;
    width: 96%;
}
@media (max-width: 1535px) {
    .s1-heading-pg3 {
        color: white !important;
        width: 100%;
    }
}
@media (max-width: 1279px) {
    .s1-heading-pg3{
        font-size: 36px;
    }
}
@media (max-width: 767px) {
    .s1-heading-pg3 {
        font-size: 25px;
        line-height: 40px;
    }
}
.s1-btn1-pg3 {
    padding: 11px 31px 11px 31px;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}
.s1-pg3-text {
    font-family: General Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    width: 90%;
    color: white;
}
@media (max-width: 1535px) {
    .s1-pg3-text {
        width: 100% !important;
    }
}
@media (max-width: 767px) {
    .s1-pg3-text {
        font-size: 18px;
    }
}

.s2-pg3-portfolio-bg{
    background: linear-gradient(87.99deg, #2E9CA4 0%, #11454D 100%) !important;
}
.s3-pg3-portfolio-bg{
    background-image: url('/public/assets/52cf9d04cd0fc761b5cdfbe1c94a90ff.png');
    background-size: inherit; 
    background-repeat: no-repeat;
    background-position: center;
    height: 550px;
}
.s3-padding-pg3{
    padding: 103px;
}
@media (max-width:1535px) {
    .s3-padding-pg3{
        padding: 30px;
    } 
}
.s3-heading-pg3{
font-family: General Sans;
font-size: 50px;
font-weight: 600;
line-height: 65px;
text-align: center;
color: #341552;
}
.s3-text-pg3{
    font-family: General Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: #341552;
    
}
.s3-text2-pg3{
    font-family: General Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #341552;
}
@media (max-width:1279px) {
    .s3-padding-pg3{
        padding: 20px;
    } 
    .s3-heading-pg3{
        font-size: 40px;
        }
}
@media (max-width:767px) {
    .s3-heading-pg3{
        font-size: 32px;
        line-height: 40px;
        }
        .s3-text-pg3{
            font-size: 18px;
            line-height: 30px;
        }
        .s3-text2-pg3{
            font-size: 18px;
        }
        .s3-pg3-portfolio-bg{
            height: 500px;
        }
}
@media (max-width:400px) {
    .s3-pg3-portfolio-bg{
        height: 550px;
    }
}
.s4-pg3-portfolio-bg{
    background: linear-gradient(88.49deg, #FD5FBD 0%, #954FEE 57.01%) !important;
}
.s4-heading-pg3{
    width: 92% !important;
}
@media (max-width:1023px) {
    .s4-heading-pg3{
        width: 100% !important;
    }
}
.s4-pg3-text{
    width: 65% !important;
}
@media (max-width:1535px) {
    .s4-pg3-text{
        width: 74% !important;
    }
}
@media (max-width:1279px) {
    .s4-pg3-text{
        width: 100% !important;
    }
}
.s5-pg3-portfolio-bg {
    background: linear-gradient(87.99deg, #E265BE 0%, #C437D7 100%) !important;
}
.s5-pg3-text{
    width: 59% !important;
}
@media (max-width:1535px) {
    .s5-pg3-text{
        width: 65% !important;
    }
}
@media (max-width:1279px) {
    .s5-pg3-text{
        width: 100% !important;
    }
}
.s6-pg3-portfolio-bg{
    background: linear-gradient(87.99deg, #1C8769 0%, #17A980 100%) !important;

}
.s6-heading-pg3{
    width: 83% !important;
}
.s6-pg3-text{
    width: 82% !important;
}
@media (max-width: 1535px) {
    .s6-heading-pg3 {
        width: 93% !important;
    }
}
@media (max-width: 1535px) {
    .s6-pg3-text {
        width: 73% !important;
    }
}
@media (max-width:1279px) {
    .s6-pg3-text {
        width: 91% !important;
    }
}
@media (max-width:1023px) {
    .s6-pg3-text {
        width: 100% !important;
    }
    .s6-heading-pg3{
        width: 100% !important;
    }
}
.s7-pg3-portfolio-bg{
    background: linear-gradient(85.96deg, #F3CA5D 0%, #E4AC19 100%)!important;
}
.s7-heading-pg3{
    width: 83% !important;
}
.s7-pg3-text {
    width: 82% !important;
}
@media (max-width:1535px) {
    .s7-pg3-text{
        width: 96% !important;
    }
    .s7-heading-pg3{
        width: 98% !important;
    }
}
@media (max-width:1279px) {
    .s7-heading-pg3{
        width: 100% !important;
    } 
    .s7-pg3-text{
        width: 100% !important;
    }
}
.s8-heading-pg3{
    width: 86%!important ;
}
@media (max-width:767px) {
    .s8-heading-pg3{
        width: 100%!important ;
    }
}
.s9-pg3-portfolio-bg{
    background: linear-gradient(85.45deg, #0EC1F4 0%, #3188DB 100%) !important;

}
.s10-pg3-portfolio-bg{
    background: linear-gradient(270deg, #EE6672 0%, #4849A1 41.58%) !important;

}
.s10-heading-pg3{
    width: 97% !important;
}
.s10-pg3-text{
    width: 90% !important;
}
@media (max-width:1279px) {
    .s10-pg3-text{
        width: 95% !important;
    }
}
@media (max-width:1023px) {
    .s10-heading-pg3{
        width: 100% !important;
    }
    .s10-pg3-text{
        width: 100% !important;
    }   
}
.s11-pg3-portfolio-bg{
    background: linear-gradient(270deg, #001B72 0%, #3454BA 58.42%) !important;

}
.s11-heading-pg3{
    width: 85% !important;
}
.s11-pg3-text{
    width: 87% !important;
}
@media (max-width:1279px) {
    .s11-heading-pg3{
        width: 100% !important;
    }
    .s11-pg3-text{
        width: 100% !important;
    }
}
.s12-pg3-portfolio-bg{
    background: linear-gradient(90deg, #479FB4 0.95%, #652A98 100%) !important;
}
.s13-pg3-portfolio-bg{
    background-image: url('/public/assets/d1f568b01f38e71b3656bc3f7b089e11.png') !important;
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    background-color: #000000E8 !important;
}
.s13-heading-pg3{
    width: 90% !important;
}
.s13-pg3-text{
    width: 87% !important;
}
@media (max-width:1535px) {
    .s13-heading-pg3{
        width: 100% !important;
    }
    .s13-pg3-text{
        width: 100% !important;
    }
}
.s14-pg3-portfolio-bg{
    background: #D8956B !important;
}
.s14-pg3-text{
    width: 94% !important;
}
@media (max-width:1279px) {
    .s14-pg3-text{
        width: 100% !important;
    }
}
.s15-pg3-portfolio-bg{
    background: linear-gradient(180deg, #EF4C37 0%, #FD7F3E 75%) !important;
}
.s15-heading-pg3 {
    width: 85% !important;
}
.s15-pg3-text {
    width: 91% !important;
}
@media (max-width:1535px) {
    .s15-heading-pg3 {
        width: 89% !important; 
    }
    .s15-pg3-text {
        width: 98% !important;
    }
}
@media (max-width:1279px) {
    .s15-heading-pg3 {
        width: 100% !important;
    }
    .s15-pg3-text {
        width: 100% !important;
    }
}
.s16-pg3-portfolio-bg{
    background-image: url('/public/assets/c545c5c5ae65f7bea17b6d813ba378ed.png') !important;
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    background-color: #2A314B !important;
}