@font-face {
    font-family: 'General Sans';
    src: url('/src/fonts/GeneralSans-Medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/src/fonts/HelveticaNeueMedium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .footer1-bg{
    background-color: #F9F3FF !important;
}
.footer-pg1-heading{
    width: 75% !important;
}
@media (max-width:1279px) {
    .footer-pg1-heading{
        width: 100% !important;
    } 
}
.footer-pg1-text1{
    width: 59%;
}
@media (max-width:1535px) {
    .footer-pg1-text1{
        width: 75%;
    }
}
@media (max-width:1279px) {
    .footer-pg1-text1{
        width: 100%;
    }
}
.fixed-height-img {
    height: 220px;
  }
  .footer1-border{
    border-top: 4px solid #8748C24D;    ;
  }
  .footer1-heading2{
    font-family: Helvetica Neue;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: black;    
  }
  .footer1-text3{
    font-family: Helvetica Neue;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #8748C2;
  }
  .footer1-tab1{
padding: 6px 8px 6px 8px;
border-radius: 4px;
background-color: #8748C21A;
box-shadow: 0px 0px 0px 1px #8748C299 inset;
width: 47%;
  }
  .footer1-box{
    width: 24px;
height: 24px;
border-radius: 2px;
background-color: #F9F3FF;
border: 1px solid #8748C299;
font-family: Helvetica;
font-size: 12px;
font-weight: 700;
line-height: 16px;
color: #8748C2;
text-align: center;
padding: 4px;
  }
  .footer1-box-heading{
    font-family: Helvetica Neue;
font-size: 20px;
font-weight: 400;
line-height: 28px;
color: #8748C2;
  }
  .footer1-btn1{
padding: 9.25px 15px 10.75px 15px;
border-radius: 4px;
background-color: #8748C2;
box-shadow: 0px 3px 12px 0px #0000001A;
font-size: 20px;
font-weight: 700;
line-height: 20px;
color: white;

  }
  .footer1-btn2{
padding:11px;
border-radius: 4px 0px 0px 4px;
background-color: #8748C2;
border-right: 0.5px solid rgb(94, 93, 93);
  }
  .footer1-btn3{
    padding: 11px;
    border-radius: 0px 4px 4px 0px;
    background-color: #8748C2;
    border-left: 0.5px solid rgb(94, 93, 93); 
  }
  @media (max-width:1279px) {
    .footer1-tab1 {
        width: 65%;
    } 
  }
  .footer-pg1-heading {
    font-family: General Sans;
    font-size: 58px;
    font-weight: 600;
    line-height: 80px;
    color: #341552;
}
@media (max-width:1279px) {
    .footer-pg1-heading {
        font-size: 44px;
    }
}
@media (max-width: 767px) {
    .footer-pg1-heading {
        font-size: 35px;
        line-height: 48px;
    }
}
.footer-pg1-text2{
    font-family: General Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: #341552;
}
.footer-pg1-text1{
    font-family: General Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    color: #341552;
}
@media (max-width: 767px) {
    .footer-pg1-text1 {
        font-size: 18px;
    }
    .footer-pg1-text2 {
        font-size: 18px;
    }
}
.footer-btn-position{
    right: 20%;
}
@media (max-width:1279px) {
    .footer-btn-position{
        right: 0%;
    }
}
@media (max-width: 1023px) {
    .footer-pg1-heading {
        width: auto !important;
    }
}
@media (max-width: 1023px) {
    .footer-pg1-text1 {
        width: auto !important;
    }
        .footer1-tab1 {
            width: 47%;
        }
}
@media (max-width:767px) {
    .fixed-height-img {
        height: 100px;
    }
    .footer1-heading2 {
        font-size: 20px;
    }
    .footer1-btn1 {
        font-size: 18px;
    }
}
@media (max-width: 625px) {
    .footer1-tab1 {
        width: 50%;
    }
}
@media (max-width: 549px) {
    .footer1-tab1 {
        width: 60%;
    }
}
@media (max-width: 450px) {
    .footer1-tab1 {
        width: 72%;
    }
}
@media (max-width: 415px) {
    .footer1-tab1 {
        width: 81%;
    }
}
.footer2-bg{
    background-color: #541F87;
}
.footer2-bg h2{
    font-family: General Sans;
font-size: 26px;
font-weight: 400;
color: #FFFFFF;
}
.footer2-bg ul li a{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
 color: #D3D3D3;
}
.footer2-li{
    color: white !important;
}
@media (max-width:1279px) {
    .footer2-bg h2{
    font-size: 20px;
    }
    .footer2-bg ul li a{
    font-size: 18px;
    }
}
.underline {
    text-decoration: underline;
  }
  .footer2-text{
    font-family: General Sans;
font-size: 18px;
font-weight: 400;
color: #FFFFFF;
  }
  .footer2-span{
    font-family: General Sans;
font-weight: 600 !important;
  }
  .footer2-social{
    width: 54px;
height: 54px;
padding: 14.5px;
border-radius: 27px;
background-color: white;
  }
  @media (max-width:767px) {
    .footer2-bg h2{
    font-size: 18px;
    }
    .footer2-bg ul li a{
    font-size: 16px;
    }
    .footer2-text{
    font-size: 16px;
      }
}
.footer2-position1{
    top: 21%;
}
.footer2-position2{
    top: 0%;
    right: 3%;
    height: 100px;
}
@media (max-width:1350px) {
    .footer2-position1 {
       height: 80px;
    }
    .footer2-position2 {
        height: 80px;
    }
}
@media (max-width:1023px) {
    .footer2-position1 {
        top: 13%;
    }
}
@media (max-width: 767px) {
    .footer2-position1 {
        height: 75px;
    }
}
@media (max-width: 639px) {
    .footer2-position1 {
        top: 10%;
    }
}
.modal-heading{
    color: black !important;
    font-weight: 600;
}
.modal-btn{
    background-color: black !important;
}
.modal-color{
    background-color: white !important;
}
.form-hr{
    background-color: black;
    height: 2px;
    width: 54%;
    border-top-width: 0px !important;
}
.modal-heading-main{
    color: black !important;
    font-size: 24px;
    font-weight: bold;
}
@media screen and (max-width:550px) {
    .modal-heading-main{
        font-size: 21px;
    }
}
.modal-text11{
    color: black !important;
    font-weight: 400;
}
.modal-custom-border {
    border: 2px solid rgb(223, 221, 221);
    border-bottom-color: black;
}

.attachment-thumbnail{
    background-color: #46A859 !important;
    padding: 4px;
    border-radius: 0 5px 5px 0px;

}
.attachment-thumbnail1{
    background-color: #4CABE9 !important;
    padding: 4px;
    border-radius: 0 5px 5px 0px;
}
.fixed-container {
    position: fixed;
    left: 0;
    top: 60%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 111;
}

.icon-wrapper {
    margin: 10px 0;
}

.quick-contact-slide-left {
    writing-mode: vertical-rl;
    transform: rotate(360deg);
    text-align: center;
}

.rotated-text {
    margin: 0;
    background-color: #541F87;
    width: 35px;
    border-radius: 0 5px 5px 0px;
}
.rotated-text a{
    font-size: 22px;
    color: white !important;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}
.attachment-thumbnail2{
    background-color: #46A859 !important;
    border-radius: 100%;
}
.attachment-thumbnail3{
    background-color: #4CABE9 !important;
    border-radius: 100%;
}
 .navbar-custom-center{
    display: none;
 }
 @media (max-width:767px) {
    .navbar-custom-center{
        display: block;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin: 0 auto;
        margin-top: 10px;
    }
 }
 @media (max-width:767px) {
    .navbar-phone{
        display: none;
    }
    .fixed-container{
        display: none;
    }
 }
 @media (max-width:1023px) {
    .navbar-custom-flex{
        flex-direction: column;
        align-items: center;
     }    
 }
