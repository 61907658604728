.navbar-services{
    background: #FAF5FF !important;
}
.header-section-services{
    background-color: white !important;
}
.hero-section-heading-services{
    color: #341552 !important;
    width: 69% !important;
}
@media (max-width:1535px) {
    .hero-section-heading-services{
        width: 73% !important;
    }  
}
@media (max-width:1279px) {
    .hero-section-heading-services{
        width: 100% !important;
    }  
}
.hero-section-text-services{
    color: #341552 !important;
}
.s3-pg2-text1{
    width: 84% !important;
}
.s3-pg2-heading{
    width: 86% !important;
}
@media (max-width:1279px) {
    .s3-pg2-heading {
        width: 100% !important;
    }
}
.star-pg2-position{
    position: absolute;
    right: 2%;
    top: 48%;
    width: 100px;
    z-index: -1;
}
@media (max-width:1279px) {
    .star-pg2-position {
        top: 67%;
        height: 130px;
    }
    .s3-pg2-text1{
        width: 100% !important;
    }
}
@media (max-width:767px) {
    .star-pg2-position {
        top: 0%;
        height: 100px;
        right: 0%;
    }
}
.pg2-s3-bg{
    background-color: #FBF7FF;
}
.heading2-s3-pg2{
    font-family: General Sans;
font-size: 30px;
font-weight: 600;
line-height: 80px;
color: #341552;

}
.moon-pg2-positon {
    position: absolute;
    bottom: -9% !important;
    right: 0;
    height: 90px;
    z-index: -1 !important;
}
@media (max-width:767px) {
    .moon-pg2-positon {
        position: absolute;
        top: 0% !important;
        right: 0;
        height: 90px;
        z-index: -1 !important;
    }
    .heading2-s3-pg2 {
        font-size: 25px;
    }
}

@media (max-width: 500px) {
    .heading2-s3-pg2 {
        font-size: 20px;
    }
}
@media (max-width: 380px) {
    .heading2-s3-pg2 {
        font-size: 19px;
    }
}
.s4-pg2-text1{
    width: 63% !important;
}
@media (max-width:1535px) {
    .s4-pg2-text1 {
        width: 76% !important;
    }
}
@media (max-width:1279px) {
    .s4-pg2-text1 {
        width: 97% !important;
    }
}
@media (max-width:1023px) {
    .s4-pg2-text1 {
        width: 100% !important;
    }
}
.card-services-pg2{
border-radius: 16px;
border: 1px solid #E8E0F5;
padding-left: 25px;
padding-right: 25px;
height: 370px;
}

.card-services-pg2-heading{
    font-family: General Sans;
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
    color: #341552;    
}
.card-services-pg2-text{
    color: #341552;
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
}
@media (max-width:1535px) {
    .card-services-pg2{
        height: 405px;
    }
}
@media (max-width:1279px) {
    .card-services-pg2{
        height: 480px;
    }
    .card-services-pg2-heading{
        font-size: 23px; 
    }
}
@media (max-width: 1023px) {
    .card-services-pg2 {
        height: 440px;
    }
}
@media (max-width: 767px) {
    .card-services-pg2 {
        height: 300px;
    }
    .card-services-pg2-heading{
        font-size: 22px;   
    }
    .card-services-pg2-text{
    font-size: 18px;
    }
    .card-services-pg2 img{
        height: 75px;
    }
}
@media (max-width: 542px) {
    .card-services-pg2 {
        height: 320px;
    }
}
@media (max-width: 399px) {
    .card-services-pg2 {
        height: 385px;
    }
}
.s6-pg2-services-bg{
    background: linear-gradient(87.99deg, #E265BE 0%, #F317B2 100%);
padding: 80px 0px 80px 68px;
border-radius: 16px;
}
@media (max-width:1023px) {
    .s6-pg2-services-bg{
        background: linear-gradient(87.99deg, #E265BE 0%, #F317B2 100%);
    padding: 40px 40px 0px 40px !important;
    border-radius: 16px;
    }
}
@media (max-width:767px) {
    .s6-pg2-services-bg{
        background: linear-gradient(87.99deg, #E265BE 0%, #F317B2 100%);
    padding: 40px 20px 0px 20px !important;
    border-radius: 16px;
    }
}

.s6-heading-pg2{
    color: white !important;
    width: 96%;
}
@media (max-width:1535px) {
    .s6-heading-pg2{
        color: white !important;
        width: 100%;
    }
}
.s6-text-pg2{
    width: 92% !important;
    color: white !important;
}
@media (max-width:1535px) {
    .s6-text-pg2{
        width: 100% !important;
    }   
}
.s13-text-pg2{
    color: white !important;
    width: 65%;
}
@media (max-width:1535px) {
    .s13-text-pg2{
        width: 80%!important;
    }   
}
@media (max-width:1279px) {
    .s13-text-pg2{
        width: 100%!important;
    }   
}
.s15-text-pg2{
    color: white !important;
    width: 66%;
}
@media (max-width:1279px) {
    .s15-text-pg2{
        width: 100%!important;
    }   
}
.s6-title-pg2{
    font-family: General Sans;
font-size: 18px;
font-weight: 500;
line-height: 21.6px;
color: white;
}
@media (max-width:767px) {
    .s6-title-pg2{
    font-size: 16px;
    }
}
.design-section-pg2-position{
    top: 7%;
    right: 0%;
}
@media (max-width:767px) {
    .design-section-pg2-position{
        height: 75px;
    }
}
.s10-heading-pg2 {
    color: white !important;
    width: 92% !important;
}
@media (max-width:1023px) {
    .s10-heading-pg2 {
        width: 100% !important;
    }
}
.s10-pg2-services-bg{
    background: linear-gradient(88.49deg, #FD5FBD 0%, #954FEE 57.01%) !important;
}
.s11-pg2-services-bg{
    background: linear-gradient(87.99deg, #E265BE 0%, #C437D7 100%) !important;

}
.s17-services-pg2-bg{
    background-image: url('/public/assets/cab4dd2d93d638f1b3738ed23b7a8eff.png');
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
}
@media (max-width:1023px) {
    .section17-img-center-services{
        display: flex;
        justify-content: center;
    }
}
.s18-services-pg2-bg{
    background-color: #F9F3FF;
}
.s18-pg2-heading{
    font-family: General Sans;
font-size: 40px;
font-weight: 500;
line-height: 48px;
color: #341552;
}
.s18-pg2-text{
    color: #341552;
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;
}
@media (max-width:767px) {
    .s18-pg2-heading{
    font-size: 28px;
    }
    .s18-pg2-text{
    font-size: 18px;
    } 
}
.s18-pg2-heading {
    width: 95% !important;
}
.review-card-pg2{
    height: 462px;
border-radius: 16px;
border: 1px solid #EFDFDF;
background-color: #FFFFFF;
padding: 25px;
}
.card-inner-star{
    padding: 0px 12px 0px 12px;
border-radius: 61px;
background-color: #F2E5FF;
}
.inner-section-text-pg2{
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 38px;
color: #341552;
}
.review-main-heading-pg2{
    font-family: General Sans;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    color: #341552;
}
.review-main-text-pg2{
    color: #341552;
    font-family: General Sans;
font-size: 20px;
font-weight: 400;
line-height: 34px;

}
.review-name-pg2{
    font-family: General Sans;
font-size: 22px;
font-weight: 500;
line-height: 26.4px;
color: #341552;
}
@media (max-width:1279px) {
    .inner-section-text-pg2{
    font-size: 18px;
    }
    .review-main-heading-pg2{
        font-size: 24px;
    }
    .review-main-text-pg2{
    font-size: 18px;
    }
    .review-name-pg2{
    font-size: 18px;
    }
    .review-card-pg2{
    padding: 20px;
    }
    .imag-review-pg2{
        height: 50px;
    }
}
@media (max-width:767px) {
    .review-card-pg2 {
        height: 360px;
    }
}
@media (max-width: 505px) {
    .review-card-pg2 {
        height: 420px;
    }
}
.s6-pg2-services-bg {
    background: linear-gradient(87.99deg, #E265BE 0%, #F317B2 100%);
    padding: 80px 0px 80px 68px;
    border-radius: 16px;
}
.hero-section-heading-services123{
    width: 100% !important;
    color: #341552 !important;
}
.service-slider .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
    background-image: url('/public/assets/Button - Previous slide → real-prev.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
}
.service-slider .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: '' !important;
    background-image: url('/public/assets/real-next.svg') !important;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: block;
}
.swiper-slider .swiper-button-prev.swiper-button-disabled,.swiper-slider .swiper-button-next.swiper-button-disabled {
    opacity: 0.50;
    cursor: auto;
    pointer-events: none;
}
.swiper-slider .swiper-button-next.swiper-button-disabled,.swiper-slider .swiper-button-prev.swiper-button-disabled {
    opacity: 0.50;
    cursor: auto;
    pointer-events: none;
}
.service-slider .swiper-button-prev{
    height: 40px !important;
    width: 40px !important;
    left: 0px !important;
}
.service-slider .swiper-button-next{
    height: 40px !important;
    width: 40px !important;
    right: 0px !important;
}
.service-slider .swiper{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
@media (max-width:767px) {
    .service-slider .swiper{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}